import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import GlobalThemeProvider from "./GlobalThemeProvider";

import AppContents from "./AppContents";
import { useLocalStorage } from "@rehooks/local-storage";

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
} from "@apollo/client";

import { Input } from "components/base";
import { Row } from "components/base/layout";

const createApolloClient = () => {
  return new ApolloClient({
    link: new HttpLink({
      uri: process.env.REACT_APP_HASURA_URI,
    }),
    cache: new InMemoryCache(),
  });
};

const client = createApolloClient();

const SetPasswordBox = ({ setPassword }) => {
  return (
    <Row width={320} marginX="auto" marginTop={200}>
      <Input.Password
        onPressEnter={(e) => setPassword(e.currentTarget.value)}
      />
    </Row>
  );
};

const OLD_PWD = "pAcho";
const NEW_PWD = "meta";

const App = () => {
  const [password, setPassword] = useLocalStorage(`_mp__password`);

  if (!password || ![OLD_PWD, NEW_PWD].includes(password)) {
    return <SetPasswordBox setPassword={setPassword} />;
  }

  return (
    <ApolloProvider client={client}>
      <Router>
        <GlobalThemeProvider>
          <AppContents />
        </GlobalThemeProvider>
      </Router>
    </ApolloProvider>
  );
};

export default App;
