import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Box from "ui-box";

import styled from "styled-components";

const StyledLink = (func) => func`
  display: block;

  ${(props) =>
    !props.unstyled &&
    `
  color: ${props.theme["$black-0"]};
  font-weight: 500;
  font-size: 15px;
  opacity: ${props.defaultOpacity !== undefined ? props.defaultOpacity : 0.5};
  padding: ${props.padding !== undefined ? props.padding : 18};
  cursor: pointer;

  &[disabled] {
    color: ${props.theme["$black-0"]};
    opacity: 0.25;
  }

  &:hover {
    color: ${props.theme["$black-0"]};
    opacity: 0.8;
  }

  &.active,
  &.active:hover {
    opacity: 1;
  }`}
`;

const StyledRouterLink = StyledLink(styled(RouterLink));
const StyledDivLink = StyledLink(styled.div);

export const Link = ({ to, active, ...props }) => {
  const Component = to ? StyledRouterLink : StyledDivLink;

  return (
    <Box is={Component} to={to} className={active && "active"} {...props} />
  );
};

export default Link;
