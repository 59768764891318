import React from "react";
import { Pane } from "components/base/layout";
import { UIProps } from "components/base/types";

export const Icon = ({
  name,
  type,
  fixedWidth,
  className,
  ...props
}: {
  name: string;
  type?: string;
  fixedWidth?: boolean;
  className?: string;
} & UIProps) => (
  <Pane
    is="i"
    className={`${type || "fal"} ${
      fixedWidth ? "fa-fw" : ""
    } ${name} ${className}`}
    {...props}
  />
);

export default Icon;
