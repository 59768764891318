import React from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import Box from "ui-box";

import styled from "styled-components";

const StyledNavLink = styled(RouterNavLink)`
  display: block;
  color: ${(props) => props.theme["$black-0"]};
  font-weight: 500;
  font-size: 15px;
  opacity: 0.5;
  padding: 18px;

  &[disabled] {
    color: ${(props) => props.theme["$black-0"]};
    opacity: 0.25;
  }

  &:hover {
    color: ${(props) => props.theme["$black-0"]};
    opacity: 0.8;
  }

  &.active,
  &.active:hover {
    opacity: 1;
  }
`;

export const NavLink = (props) => {
  return <Box is={StyledNavLink} {...props} />;
};

export default NavLink;
