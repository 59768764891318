import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Pane } from "components/base/layout";
import ConnectionsPage from "pages/ConnectionsPage";
import DataPage from "pages/DataPage";
const DashboardsPage = () => <></>;

const PageContent = () => (
  <Pane>
    <Switch>
      <Route path="/d/t/:tableId">
        <DataPage />
      </Route>
      <Redirect from="/data/table/:tableId" to="/d/t/:tableId" />
      <Route path="/d">
        <DataPage />
      </Route>
      <Redirect from="/data" to="/d" />
      <Route path="/dashboards">
        <DashboardsPage />
      </Route>
      <Route path="/settings/connections">
        <ConnectionsPage />
      </Route>
      <Route exact path="/">
        <Redirect to="/d" />
      </Route>
    </Switch>
  </Pane>
);

export default PageContent;
