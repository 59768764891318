import React from "react";
import { Pane } from "components/base/layout";
import CommentRow from "./CommentRow";

const CommentPopoverComments = ({ comments, setComments }) => {
  if (!comments || !comments.length) {
    return <></>;
  }

  const deleteComment = (commentToDelete) => {
    setComments(
      comments.filter(
        (comment) =>
          !(
            comment.createdAt === commentToDelete.createdAt &&
            comment.html === commentToDelete.html
          )
      )
    );
  };

  return (
    <Pane>
      {comments.map((comment) => (
        <CommentRow comment={comment} deleteComment={deleteComment} />
      ))}
    </Pane>
  );
};

export default CommentPopoverComments;
