import React from "react";
import { Row } from "./Row";
import { UIProps } from "components/base/types";

export const Column = ({
  centerX,
  centerY,
  center,
  ...props
}: {
  centerX?: boolean;
  centerY?: boolean;
  center?: boolean;
} & UIProps) => {
  const alignItems: string | undefined =
    centerX || center ? "center" : undefined;
  const justifyContent: string | undefined =
    centerY || center ? "center" : undefined;

  return (
    <Row
      display="flex"
      flexDirection="column"
      alignItems={alignItems}
      justifyContent={justifyContent}
      {...props}
    />
  );
};
