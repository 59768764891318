import React from "react";

import { Tag as AntdTag } from "antd";
import styled from "styled-components";

type AntdInputProps = React.ComponentProps<typeof AntdTag>;

export const Tag = styled(AntdTag)`
  &.ant-tag {
    font-weight: 600;
    opacity: 0.8;
    background: white;
  }
`;

export default Tag;
