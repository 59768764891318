import React from "react";
import { Pane, Row } from "components/base/layout";
import { useLocalStorage } from "@rehooks/local-storage";
import CommentPopoverController from "components/CommentPopoverController";
import Editor from "components/Editor";

import {
  SALESFORCE_ACCOUNTS_TABLE_ID,
  SALESFORCE_ACCOUNTS_TABLE_DESCRIPTION_PLACEHOLDER,
} from "constants/defaults";

const TableDescription = ({ tableId }) => {
  const [description, setDescription] = useLocalStorage(
    `_mp_${tableId}-description-blocks`
  );

  if (!description && tableId === SALESFORCE_ACCOUNTS_TABLE_ID) {
    setDescription(SALESFORCE_ACCOUNTS_TABLE_DESCRIPTION_PLACEHOLDER);
  }

  return (
    <Row>
      <Pane width={650}>
        <Editor
          key={!!description}
          hideToolbar
          hideToolbox
          placeholder="Add a table description..."
          id={`table-${tableId}-description`}
          onChange={setDescription}
          data={description}
          minWidth={300}
        />
      </Pane>
      <CommentPopoverController
        marginLeft={40}
        storageKey={`_mp_${tableId}-comments`}
      />
    </Row>
  );
};

export default TableDescription;
