import React from "react";

import { UIProps } from "components/base/types";
import Box from "ui-box";

export const Stat = ({
  name,
  children,
  ...props
}: { name: string } & UIProps) => (
  <Box paddingRight={28} {...props}>
    <Box textTransform="uppercase" fontSize={11} opacity={0.5}>
      {name}
    </Box>
    {children}
  </Box>
);

export default Stat;
