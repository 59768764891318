import React from "react";

import { Button as AntdButton } from "antd";
import { UIProps } from "components/base/types";
import Box from "ui-box";
import styled from "styled-components";

type AntdButtonProps = React.ComponentProps<typeof AntdButton>;

const StyledButton = styled(AntdButton)`
  font-weight: 600;
  padding: 7px 16px;
  height: auto;
  font-size: 13px;
  background: hsla(212, 25%, 16%, 1);
  border-color: hsla(212, 25%, 16%, 1);

  &:hover,
  &:focus,
  &:active {
    background: hsla(212, 25%, 30%, 1);
    border-color: hsla(212, 25%, 30%, 1);
  }
`;

export const Button = (props: AntdButtonProps & UIProps) => (
  <Box is={StyledButton} {...props} />
);

export default Button;
