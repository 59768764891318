import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { Link } from "components/base";
import { H2, Code } from "components/base/type";
import { Pane, Row } from "components/base/layout";
import AppContext from "AppContext";
import { getTableSlug } from "utils";
import TablePage from "./TablePage";

const DataPage = () => {
  const { tableId } = useParams();
  const { tables } = useContext(AppContext);

  if (!tables) {
    return <>Loading...</>;
  }

  const isTableIdASlug = tableId && tableId.includes(".");

  const table =
    tableId &&
    tables.find((table) =>
      isTableIdASlug ? getTableSlug(table) === tableId : table.id === tableId
    );

  return (
    <Pane paddingX={72} paddingY={45}>
      {!tableId && tables && (
        <Row wrap justifyContent="space-between">
          {tables.map((table) => (
            <Link
              to={`/d/t/${getTableSlug(table)}`}
              width={300}
              defaultOpacity={0.7}
              padding={0}
              marginBottom={24}
            >
              <Pane
                fontSize={12}
                textTransform="uppercase"
                opacity={0.5}
                marginTop={-10}
                marginBottom={2}
              >
                <Code>{table.db}</Code>
              </Pane>

              <H2
                marginBottom={21}
                fontWeight={500}
                fontSize={16}
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {table.schema.toLowerCase()}.{table.name.toLowerCase()}
              </H2>
            </Link>
          ))}
        </Row>
      )}
      {table && <TablePage table={table} />}
    </Pane>
  );
};
export default DataPage;
