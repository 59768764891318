import React from "react";
import styled from "styled-components";
import { UIProps } from "components/base/types";
import { Pane, Row } from "components/base/layout";
import { Tooltip } from "antd";

const StyledPersonIcon = styled(Pane)`
  box-sizing: content-box;
`;

export const PersonIcon = ({
  name,
  email,
  size,
  active,
  ...props
}: {
  name: string;
  email: string;
  size?: number;
  active?: boolean;
} & UIProps) => {
  const DEFAULT_ICON_SIZE: number = 23;
  const iconSize: number = size || DEFAULT_ICON_SIZE;

  return (
    <Pane
      className="person-icon"
      width={iconSize}
      height={iconSize}
      borderRadius={30}
      position="relative"
      {...props}
    >
      <Tooltip
        title={
          <Pane>
            <Pane>{name}</Pane>
            <Pane opacity={0.6}>({email})</Pane>
          </Pane>
        }
        placement="topRight"
      >
        <Pane
          backgroundColor="white"
          borderRadius="100%"
          boxShadow="rgba(15, 15, 15, 0.1) 0px 2px 4px"
        >
          <StyledPersonIcon
            border="1px solid currentcolor"
            borderRadius="100%"
            width={iconSize - 2}
            height={iconSize - 2}
            lineHeight={`${iconSize - 2}px`}
            overflow="hidden"
            textAlign="center"
            fontSize={14}
            background="white"
            userSelect="none"
            color="rgb(55, 53, 47)"
            opacity={active ? 1 : 0.3}
          >
            <Pane>{name[0].toUpperCase()}</Pane>
          </StyledPersonIcon>
        </Pane>
      </Tooltip>
    </Pane>
  );
};

PersonIcon.Group = styled(Row)`
  .person-icon:not(:last-child) {
    margin-right: -5px;
  }
`;

export default PersonIcon;
