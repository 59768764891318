import React, { useState } from "react";
import { Pane } from "components/base/layout";
import { useLocalStorage } from "@rehooks/local-storage";
import { Popover } from "antd";
import CommentPopoverButton from "./CommentPopoverButton";
import CommentPopoverContents from "./CommentPopoverContents";

import {
  SALESFORCE_ACCOUNTS_TABLE_ID,
  SALESFORCE_ACCOUNTS_TABLE_COMMENTS_PLACEHOLDER,
  CLEAN_NAME_COLUMN_ID,
  CLEAN_NAME_COLUMN_COMMENTS_PLACEHOLDER,
} from "constants/defaults";

const SALESFORCE_ACCOUNTS_TABLE_COMMENTS_KEY = `_mp_${SALESFORCE_ACCOUNTS_TABLE_ID}-comments`;
const CLEAN_NAME_COMMENTS_KEY = `_mp_${CLEAN_NAME_COLUMN_ID}-comments`;

const CommentPopoverController = ({
  storageKey,
  hideUnlessCommentsExist,
  ...props
}) => {
  const [comments, setComments] = useLocalStorage(storageKey);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  if (
    !comments &&
    [SALESFORCE_ACCOUNTS_TABLE_COMMENTS_KEY, CLEAN_NAME_COMMENTS_KEY].includes(
      storageKey
    )
  ) {
    if (storageKey === SALESFORCE_ACCOUNTS_TABLE_COMMENTS_KEY) {
      setComments(SALESFORCE_ACCOUNTS_TABLE_COMMENTS_PLACEHOLDER);
    } else {
      setComments(CLEAN_NAME_COLUMN_COMMENTS_PLACEHOLDER);
    }
  }

  return (
    <Pane key={storageKey} {...props}>
      <Popover
        content={
          <CommentPopoverContents
            id={storageKey}
            comments={comments}
            setComments={setComments}
            closePopover={() => setIsPopoverVisible(false)}
          />
        }
        trigger="click"
        placement="bottomLeft"
        visible={isPopoverVisible}
        onVisibleChange={setIsPopoverVisible}
      >
        <CommentPopoverButton
          comments={comments}
          hideUnlessCommentsExist={hideUnlessCommentsExist}
        />
      </Popover>
    </Pane>
  );
};

export default CommentPopoverController;
