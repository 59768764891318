export const SALESFORCE_ACCOUNTS_TABLE_ID =
  "a0be795c-43cd-4a22-a00d-24785e40cb32";

export const SALESFORCE_ACCOUNTS_TABLE_DESCRIPTION_PLACEHOLDER = {
  time: 1601068469736,
  blocks: [
    {
      type: "paragraph",
      data: {
        text:
          'Canonical accounts table synced by <a href="https://www.fivetran.com">Fivetran</a>&nbsp;(owned by DE team, @Jocelyn Chen) on a daily basis.',
      },
    },
    {
      type: "paragraph",
      data: {
        text:
          "Sales and customer success teams are treating this as the source-of-truth, though we are still transitioning from Google Sheets.",
      },
    },
    {
      type: "paragraph",
      data: {
        text: "<i>Changes in Salesforce may not be reflected immediately.</i>",
      },
    },
    {
      type: "paragraph",
      data: {
        text:
          '👉 Use this, not the old <code class="inline-code">HUBSPOT.ACCOUNT</code>.',
      },
    },
  ],
  version: "2.18.0-mp.4.2.0",
  html:
    '<div class="ce-block"><div class="ce-block__content"><div class="ce-paragraph cdx-block" data-placeholder="Add a table description...">Canonical accounts table synced by <a href="https://www.fivetran.com">Fivetran</a>&nbsp;(owned by DE team, @Jocelyn Chen) on a daily basis.</div></div></div><div class="ce-block"><div class="ce-block__content"><div class="ce-paragraph cdx-block" data-placeholder="Add a table description...">Sales and customer success teams are treating this as the source-of-truth, though we are still transitioning from Google Sheets.</div></div></div><div class="ce-block"><div class="ce-block__content"><div class="ce-paragraph cdx-block" data-placeholder="Add a table description..."><i>Changes in Salesforce may not be reflected immediately.</i></div></div></div><div class="ce-block"><div class="ce-block__content"><div class="ce-paragraph cdx-block" data-placeholder="Add a table description...">👉 Use this, not the old <code class="inline-code">HUBSPOT.ACCOUNT</code>.</div></div></div><div class="ce-block"><div class="ce-block__content"><div class="ce-paragraph cdx-block" data-placeholder="Add a table description..."></div></div></div>',
  text:
    "Canonical accounts table synced by Fivetran (owned by DE team, @Jocelyn Chen) on a daily basis.Sales and customer success teams are treating this as the source-of-truth, though we are still transitioning from Google Sheets.Changes in Salesforce may not be reflected immediately.👉 Use this, not the old HUBSPOT.ACCOUNT.",
};

export const SALESFORCE_ACCOUNTS_TABLE_COMMENTS_PLACEHOLDER = [
  {
    createdAt: 1598496823785,
    name: "Guru Mahendran",
    email: "guru@metaplane.dev",
    blocks: [
      {
        type: "paragraph",
        data: {
          text:
            "Hey team! This is still a WIP, especially because new customers are coming in every day 🙏. I have a workflow set up in Google Sheets but please bear with an occasional delay.",
        },
      },
    ],
    html:
      '<div class="ce-block"><div class="ce-block__content"><div class="ce-paragraph cdx-block" contenteditable="true" data-placeholder="Add a comment...">Hey team! This is still a WIP, especially because new customers are coming in every day 🙏. I have a workflow set up in Google Sheets but please bear with an occasional delay.</div></div></div>',
  },
  {
    createdAt: 1598496929922,
    name: "Peter Casinelli",
    email: "peter@metaplane.dev",
    blocks: [
      {
        type: "paragraph",
        data: {
          text:
            'I have a dashboard hooked up to <code class="inline-code">HUBSPOT.ACCOUNT</code> -- how long do I have before I have to make the switch?',
        },
      },
    ],
    html:
      '<div class="ce-block"><div class="ce-block__content"><div class="ce-paragraph cdx-block" contenteditable="true" data-placeholder="Add a comment...">I have a dashboard hooked up to <code class="inline-code">HUBSPOT.ACCOUNT</code> -- how long do I have before I have to make the switch?</div></div></div>',
  },
  {
    createdAt: 1598497027102,
    name: "Guru Mahendran",
    email: "guru@metaplane.dev",
    blocks: [
      {
        type: "paragraph",
        data: {
          text:
            'The <code class="inline-code">HUBSPOT.ACCOUNT</code> table has actually been out of dates <i>for weeks</i>. I\'ll track down the exact date but you should switch as soon as possible.',
        },
      },
    ],
    html:
      '<div class="ce-block"><div class="ce-block__content"><div class="ce-paragraph cdx-block" contenteditable="true" data-placeholder="Add a comment...">The <code class="inline-code">HUBSPOT.ACCOUNT</code> table has actually been out of dates <i>for weeks</i>. I\'ll track down the exact date but you should switch as soon as possible.</div></div></div>',
  },
  {
    createdAt: 1598497094734,
    name: "Kevin Hu",
    email: "kevin@metaplane.dev",
    blocks: [
      {
        type: "paragraph",
        data: {
          text:
            "Thank you!!! I was waiting for us to get this in order. Looking forward to the updates! 🙇",
        },
      },
    ],
    html:
      '<div class="ce-block"><div class="ce-block__content"><div class="ce-paragraph cdx-block" contenteditable="true" data-placeholder="Add a comment...">Thank you!!! I was waiting for us to get this in order. Looking forward to the updates! 🙇</div></div></div>',
  },
];

export const CLEAN_NAME_COLUMN_ID = "c41bb54a-0987-47c0-8c15-897a9d0fa17c";
export const UNCLEAN_NAME_COLUMN_ID = "6954696c-aa49-450f-833c-8fd94727622d";

export const CLEAN_NAME_COLUMN_DESCRIPTION_BLOCKS = {
  time: 1601069483680,
  blocks: [
    {
      type: "paragraph",
      data: {
        text:
          'Company legal name after being populated by the SDR team (cc @Sam Munster). Use this for contract and billing, but use <code class="inline-code">INFORMAL_NAME_C</code> when talking to customers.',
      },
    },
  ],
  version: "2.18.0-mp.4.2.0",
  html:
    '<div class="ce-block"><div class="ce-block__content"><div class="ce-paragraph cdx-block" data-placeholder="Add a column description...">Company legal name after being populated by the SDR team (cc @Sam Munster). Use this for contract and billing, but use <code class="inline-code">INFORMAL_NAME_C</code> when talking to customers.</div></div></div>',
  text:
    "Company legal name after being populated by the SDR team (cc @Sam Munster). Use this for contract and billing, but use INFORMAL_NAME_C when talking to customers.",
};

export const CLEAN_NAME_COLUMN_COMMENTS_PLACEHOLDER = [
  {
    createdAt: 1601057400000,
    name: "Guru Mahendran",
    email: "guru@metaplane.dev",
    blocks: [
      {
        type: "paragraph",
        data: {
          text:
            "Hey team! This is still a WIP, especially because new customers are coming in every day 🙏. I have a workflow set up in Google Sheets but please bear with an occasional delay.",
        },
      },
    ],
    html:
      '<div class="ce-block ce-block--focused"><div class="ce-block__content"><div class="ce-paragraph cdx-block" data-placeholder="Add a comment...">Hey team! This is still a WIP, especially because new customers are coming in every day 🙏. I have a workflow set up in Google Sheets but please bear with an occasional delay.</div></div></div>',
    text:
      "Hey team! This is still a WIP, especially because new customers are coming in every day 🙏. I have a workflow set up in Google Sheets but please bear with an occasional delay.",
  },
];

export const UNCLEAN_NAME_COLUMN_DESCRIPTION_BLOCKS = {
  time: 1601069496908,
  blocks: [
    {
      type: "paragraph",
      data: {
        text:
          'Company legal name as populated by <a href="http://clearbit.com/" target="_blank">Clearbit</a>, but not cleaned by the SDR team (cc @Sam Munster). Do not use externally. Use <code class="inline-code">CLEAN_NAME_C</code> instead.',
      },
    },
    {
      type: "paragraph",
      data: {
        text:
          'Do use this for joins with other tables populated by Clearbit, like <code class="inline-code">MIXPANEL.PEOPLE.COMPANY_NAME</code>.',
      },
    },
  ],
  version: "2.18.0-mp.4.2.0",
  html:
    '<div class="ce-block"><div class="ce-block__content"><div class="ce-paragraph cdx-block" data-placeholder="Add a column description...">Company legal name as populated by <a href="http://clearbit.com/" target="_blank">Clearbit</a>, but not cleaned by the SDR team (cc @Sam Munster). Do not use externally. Use <code class="inline-code">CLEAN_NAME_C</code> instead.</div></div></div><div class="ce-block"><div class="ce-block__content"><div class="ce-paragraph cdx-block" data-placeholder="Add a column description...">Do use this for joins with other tables populated by Clearbit, like <code class="inline-code">MIXPANEL.PEOPLE.COMPANY_NAME</code>.</div></div></div>',
  text:
    "Company legal name as populated by Clearbit, but not cleaned by the SDR team (cc @Sam Munster). Do not use externally. Use CLEAN_NAME_C instead.Do use this for joins with other tables populated by Clearbit, like MIXPANEL.PEOPLE.COMPANY_NAME.",
};
