import React from "react";
import { Pane } from "components/base/layout";
import CommentPopoverComments from "./CommentPopoverComments";
import CommentPopoverFooter from "./CommentPopoverFooter";

const CommentPopoverContents = ({
  id,
  comments,
  setComments,
  closePopover,
}) => {
  const addComment = (comment) => {
    const existingComments = comments && comments.length ? comments : [];

    setComments([...existingComments, comment]);
  };

  return (
    <Pane width={450}>
      <CommentPopoverComments comments={comments} setComments={setComments} />
      <CommentPopoverFooter
        id={id}
        closePopover={closePopover}
        addComment={addComment}
        hasExistingComments={comments && !!comments.length}
      />
    </Pane>
  );
};

export default CommentPopoverContents;
