export const getFormattedNumber = (number, fixed = 0) => {
  let formattedNumber = `${number}`;
  if (number >= 1000000) {
    formattedNumber = `${
      Math.round(number / (1000000 / Math.pow(10, fixed))) / Math.pow(10, fixed)
    }M`;
  } else if (number >= 1000) {
    formattedNumber = `${
      Math.round(number / (1000 / Math.pow(10, fixed))) / Math.pow(10, fixed)
    }K`;
  }
  return formattedNumber;
};

export const getTableSlug = (table) =>
  `${table.db.toLowerCase()}.${table.schema.toLowerCase()}.${table.name.toLowerCase()}`;
