import React, { useState } from "react";
import { H3, H2 } from "components/base/type";
import { Pane, Column, Row, Span } from "components/base/layout";
import { Image, Button, Input, Icon } from "components/base";
import { Drawer, Form, Switch, Tabs, Slider, Checkbox } from "antd";
import styled from "styled-components";

const DATABASES = [
  {
    name: "PostgreSQL",
    logoFileName: "integration-tile_postgres-sql.svg",
  },
  {
    name: "Snowflake",
    logoFileName: "integration-tile_snowflake.svg",
  },
  {
    name: "Google BigQuery",
    logoFileName: "integration-tile_google-big-query.svg",
  },
  {
    name: "Amazon Redshift",
    logoFileName: "integration-tile_redshift.svg",
  },
];

const BI = [
  {
    name: "Looker",
    logoFileName: "integration-tile_looker.svg",
  },
  {
    name: "Tableau",
    logoFileName: "integration-tile_tableau.svg",
  },
  {
    name: "Mode",
    logoFileName: "integration-tile_mode.svg",
  },
  {
    name: "Redash",
    logoFileName: "integration-tile_redash.svg",
  },
];

const APPS = [
  {
    name: "Salesforce",
    logoFileName: "integration-tile_salesforce.svg",
  },
];

const StyledCard = styled(Column)`
  cursor: pointer;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: #eff2f5;
  }
`;

const IntegrationCard = (props) => (
  <StyledCard
    marginRight={25}
    marginBottom={25}
    centerX
    width={262}
    height={110}
    paddingX={20}
    backgroundColor="#f5f7f9"
    borderRadius={6}
    paddingY={10}
    {...props}
  />
);

IntegrationCard.Image = ({ logoFileName }) => (
  <Image
    width="100%"
    maxWidth={225}
    maxHeight={90}
    height="auto"
    src={`/integration-logos/${logoFileName}`}
  />
);

const IntegrationCardGroup = (props) => <Pane maxWidth={574} {...props} />;
IntegrationCardGroup.CardsRow = (props) => (
  <Row marginTop={29} marginBottom={40} wrap {...props} />
);

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

const ConnectionCard = (props) => (
  <Pane
    padding={22}
    border="1px solid hsla(210, 25%, 90%, 1)"
    borderRadius={6}
    width={350}
    marginRight={24}
    {...props}
  />
);

const ConnectionsPage = () => {
  const [connectionName, setConnectionName] = useState();
  const [connections, setConnections] = useState([
    { name: "Snowflake Prod", isEnabled: true, isDefault: true },
  ]);

  const onFinish = (values) => {
    setConnections([...connections, { name: values.name, isEnabled: true }]);
    setConnectionName();
  };

  return (
    <Pane paddingX={72} paddingY={45}>
      <H2 marginBottom={41}>Your connections</H2>
      <Row wrap>
        {connections.map((connection) => (
          <ConnectionCard>
            <Row marginBottom={10}>
              <Span>{connection.name}</Span>
              <Switch
                style={{ marginLeft: "auto" }}
                checkedChildren={<Icon name="fa-check" />}
                checked={connection.isEnabled}
              />
            </Row>
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="Status" key="1">
                {connection.isDefault && (
                  <>
                    <Row fontSize={11} fontWeight={300}>
                      <Pane>07/01</Pane>
                      <Pane marginLeft="auto">08/19</Pane>
                    </Row>
                    <Row
                      justifyContent="space-between"
                      marginTop={7}
                      marginBottom={14}
                    >
                      {[...Array(30)].map(() => (
                        <Pane width={2} height={25} backgroundColor="#A5DFFF" />
                      ))}
                    </Row>
                  </>
                )}

                {connection.isDefault ? "Last sync: 1 hour ago" : "Syncing..."}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Logs" key="2">
                Logs
              </Tabs.TabPane>
              <Tabs.TabPane tab="Setup" key="3">
                <Row centerY>
                  <Span flexShrink={0} marginRight={12} fontWeight={500}>
                    Sync schema only
                  </Span>
                  <Checkbox />
                </Row>
                <Row marginTop={12} centerY>
                  <Span flexShrink={0} marginRight={12} fontWeight={500}>
                    Sync frequency
                  </Span>{" "}
                  <Slider
                    style={{ width: "100%" }}
                    defaultValue={50}
                    tooltipVisible
                    tipFormatter={(value) => {
                      if (value >= 0 && value <= 20) {
                        return "1 week";
                      }
                      if (value > 20 && value <= 40) {
                        return "2 days";
                      }
                      if (value > 40 && value <= 60) {
                        return "1 day";
                      }
                      if (value > 60 && value <= 70) {
                        return "12 hours";
                      }
                      if (value > 70 && value <= 80) {
                        return "6 hours";
                      }
                      if (value > 80 && value <= 90) {
                        return "1 hour";
                      }
                      if (value > 90 && value <= 100) {
                        return "5 minutes";
                      }
                    }}
                  />
                </Row>
              </Tabs.TabPane>
            </Tabs>
          </ConnectionCard>
        ))}
      </Row>
      <H2 marginBottom={41} marginTop={81}>
        Available connections
      </H2>
      <Row wrap>
        <IntegrationCardGroup marginRight={86}>
          <H3>Databases</H3>
          <IntegrationCardGroup.CardsRow>
            {DATABASES.map((item) => (
              <IntegrationCard onClick={() => setConnectionName(item.name)}>
                <IntegrationCard.Image logoFileName={item.logoFileName} />
              </IntegrationCard>
            ))}
          </IntegrationCardGroup.CardsRow>
        </IntegrationCardGroup>
        <IntegrationCardGroup>
          <H3>Business Intelligence</H3>
          <IntegrationCardGroup.CardsRow>
            {BI.map((item) => (
              <IntegrationCard onClick={() => setConnectionName(item.name)}>
                <IntegrationCard.Image logoFileName={item.logoFileName} />
              </IntegrationCard>
            ))}
          </IntegrationCardGroup.CardsRow>
        </IntegrationCardGroup>
        <IntegrationCardGroup>
          <H3>Apps</H3>
          <IntegrationCardGroup.CardsRow>
            {APPS.map((item) => (
              <IntegrationCard onClick={() => setConnectionName(item.name)}>
                <IntegrationCard.Image logoFileName={item.logoFileName} />
              </IntegrationCard>
            ))}
          </IntegrationCardGroup.CardsRow>
        </IntegrationCardGroup>
      </Row>

      <Drawer
        title={`Connect ${connectionName}`}
        placement="right"
        width={600}
        onClose={() => {
          setConnectionName();
        }}
        visible={!!connectionName}
      >
        <Pane paddingRight={30} paddingLeft={30}>
          <Form
            {...layout}
            name="basic"
            labelAlign="left"
            colon={false}
            onFinish={onFinish}
          >
            <Form.Item label="Name" name="name">
              <Input placeholder={`${connectionName} Prod`} />
            </Form.Item>
            <Form.Item label="Host" name="host">
              <Input placeholder={`127.0.0.1`} />
            </Form.Item>
            <Form.Item label="Port" name="port">
              <Input placeholder={`5432`} />
            </Form.Item>
            <Form.Item label="Database" name="db">
              <Input placeholder={`database name`} />
            </Form.Item>

            <Pane
              marginTop={60}
              marginBottom={16}
              fontWeight={500}
              color="#171E26"
            >
              Credentials
            </Pane>

            <Form.Item label="Username" name="username">
              <Input placeholder={`user name`} />
            </Form.Item>
            <Form.Item label="Password" name="password">
              <Input.Password placeholder={`password`} />
            </Form.Item>

            <Row>
              <Button
                type="primary"
                htmlType="submit"
                marginLeft="auto"
                marginTop={30}
              >
                Connect
              </Button>
            </Row>
          </Form>
        </Pane>
      </Drawer>
    </Pane>
  );
};

//
//             <Controller
//               as={<Input style={{ marginLeft: 8, width: "60%" }} />}
//               name="Host"
//               defaultValue={member.middleName}
//               placeholder="Middle"
//               control={control}
//             />
//             <Controller
//               as={<Input style={{ marginLeft: 8, width: "60%" }} />}
//               name="Port"
//               defaultValue={member.middleName}
//               placeholder="Middle"
//               control={control}
//             />
//             <Controller
//               as={<Input style={{ marginLeft: 8, width: "60%" }} />}
//               name="DB"
//               defaultValue={member.middleName}
//               placeholder="Middle"
//               control={control}
//             />

export default ConnectionsPage;
