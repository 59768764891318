import React, { useState, useContext } from "react";
import { PersonIcon, Button } from "components/base";
import { Row } from "components/base/layout";
import Editor from "components/Editor";
import { useQuery } from "utils/hooks";
import AppContext from "AppContext";

const CommentPopoverFooter = ({
  id,
  closePopover,
  addComment,
  hasExistingComments,
}) => {
  const [editorRef, setEditorRef] = useState(null);

  const query = useQuery();
  const { users } = useContext(AppContext);

  const currentUser = users.find(
    ({ email }) => email === (query.get("email") || "guru@metaplane.dev")
  ) || { email: query.get("email") };

  const sendComment = async () => {
    const { time, blocks, html, text } = await editorRef.save();

    if (!hasExistingComments) {
      closePopover();
    }

    addComment({
      createdAt: time,
      name: currentUser.name,
      email: currentUser.email,
      blocks,
      html,
      text,
    });

    editorRef.clear();

    if (hasExistingComments) {
      editorRef.focus();
    }
  };

  return (
    <Row alignItems="flex-start">
      <PersonIcon
        name={currentUser.name}
        email={currentUser.email}
        active
        marginRight={8}
        marginTop={7}
        size={20}
      />
      <Editor
        setEditorRef={setEditorRef}
        hideToolbar
        hideToolbox
        autofocus
        onEnterPressed={sendComment}
        id={`comments-${id}`}
        placeholder="Add a comment..."
        width="100%"
      />

      <Button type="primary" onClick={sendComment}>
        Send
      </Button>
    </Row>
  );
};

export default CommentPopoverFooter;
