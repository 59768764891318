import React from "react";
import { Row } from "components/base/layout";
import { NavLink } from "components/base";

const NavigationBar = () => (
  <Row paddingX={54} marginTop={13} borderBottom="1px solid #F2F2F2">
    <NavLink to="/d">Data</NavLink>
    <NavLink to="/dashboards" marginLeft={20}>
      Dashboards
    </NavLink>
    <NavLink to="/metrics" marginLeft={20} disabled>
      Metrics
    </NavLink>
    <NavLink to="/notebooks" marginLeft={20} disabled>
      Notebooks
    </NavLink>
    <NavLink to="/people" marginLeft={20} disabled>
      People
    </NavLink>
    <NavLink to="/pipelines" marginLeft={20} disabled>
      Pipelines
    </NavLink>
    <NavLink to="/jobs" marginLeft={20} disabled>
      Jobs
    </NavLink>
  </Row>
);

export default NavigationBar;
