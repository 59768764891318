import React, { useMemo } from "react";
import { useQuery, gql } from "@apollo/client";
import Header from "./Header";
import NavigationBar from "./NavigationBar";
import PageContent from "./PageContent";
import AppContext from "AppContext";
import { Pane } from "components/base/layout";
import moment from "moment";

const GET_TABLES = gql`
  query GetTables {
    tables {
      col_count
      created
      db
      id
      name
      owner
      row_count
      schema
      last_altered
    }
  }
`;

const getRandomizedLastAlerted = (lastAltered) => {
  if (!lastAltered) {
    return lastAltered;
  }

  const randomNumber = Math.random() * 8 * 24;

  const randomizedLastAltered = moment()
    .subtract(randomNumber, "hours")
    .format();

  return randomizedLastAltered;
};

const USERS = [
  {
    id: "1",
    name: "Guru Mahendran",
    email: "guru@metaplane.dev",
    organization_id: "mp",
    createdAt: "1598463836646",
    updatedAt: "1598463836646",
  },
  {
    id: "2",
    name: "Peter Casinelli",
    email: "peter@metaplane.dev",
    organization_id: "mp",
    createdAt: "1598463836646",
    updatedAt: "1598463836646",
  },
  {
    id: "3",
    name: "Kevin Hu",
    email: "kevin@metaplane.dev",
    organization_id: "mp",
    createdAt: "1598463836646",
    updatedAt: "1598463836646",
  },
  {
    id: "4",
    name: "Owais Khan",
    email: "owais@metaplane.dev",
    organization_id: "mp",
    createdAt: "1598463836646",
    updatedAt: "1598463836646",
  },
];

const AppContents = () => {
  const { data } = useQuery(GET_TABLES);

  const alteredTables = useMemo(
    () =>
      data
        ? data.tables.map((table) => ({
            ...table,
            last_altered: getRandomizedLastAlerted(table.last_altered),
          }))
        : [],
    [data]
  );

  return (
    <AppContext.Provider value={{ tables: alteredTables, users: USERS }}>
      <Pane>
        <Header />
        <NavigationBar />
        <PageContent />
      </Pane>
    </AppContext.Provider>
  );
};

export default AppContents;
