import React from "react";
import { Icon, PersonIcon } from "components/base";
import { Pane, Row } from "components/base/layout";
import styled from "styled-components";
import { Menu, Dropdown } from "antd";
import moment from "moment";

const CommentRow = ({ comment, deleteComment }) => {
  return (
    <StyledCommentRow key={comment.createdAt} marginBottom={6}>
      <PersonIcon
        name={comment.name}
        email={comment.email}
        active
        marginRight={8}
        marginTop={2}
        userSelect="none"
        size={20}
      />
      <Pane flexGrow={1}>
        <Row alignItems="baseline" userSelect="none" position="relative">
          <Pane fontWeight={600}>{comment.name}</Pane>
          <Pane
            fontSize={12}
            lineHeight="16px"
            color="rgba(55, 53, 47, 0.4)"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            marginLeft={6}
          >
            {moment(comment.createdAt).fromNow()}
          </Pane>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  onClick={() =>
                    window.confirm("Would you like to delete this comment?") &&
                    deleteComment(comment)
                  }
                >
                  Delete comment
                </Menu.Item>
              </Menu>
            }
            trigger="click"
          >
            <StyledMenuButton className="onHover">
              <Icon name="fa-ellipsis-h" />
            </StyledMenuButton>
          </Dropdown>
        </Row>
        <StyledComment dangerouslySetInnerHTML={{ __html: comment.html }} />
      </Pane>
    </StyledCommentRow>
  );
};

const StyledCommentRow = styled(Row)`
  .onHover {
    opacity: 0;
  }
  &:hover .onHover {
    opacity: 0.6;
  }
  &:hover .onHover:hover {
    opacity: 1;
  }
`;

const StyledComment = styled(Pane)`
  line-height: 21px;
  .cdx-block {
    padding: 0;
  }
  .ce-paragraph {
    line-height: 21px;
  }
`;

const StyledMenuButton = styled(Pane)`
  display: flex;
  flex-shrink: 0;
  border-radius: 3px;
  margin-top: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
  transition: opacity 100ms ease-out 0s;
  height: 24px;
  width: 24px;

  align-items: center;
  justify-content: center;
  font-size: 20px;
  opacity: 0.6;
  cursor: pointer;
`;

export default CommentRow;
