import React, { useRef, useEffect } from "react";
import EditorJs from "quantifai-react-editor-js";
import EditorJsType, {
  EditorConfig,
  OutputData,
  API,
} from "quantifai-editorjs";

import { UIProps } from "components/base/types";
import { Pane } from "components/base/layout";

const InlineCode: any = require("quantifai-inline-code");

type UsefulEditorJsProps = Pick<
  EditorConfig,
  "placeholder" | "autofocus" | "data" | "onEnterPressed"
> & {
  hideToolbar?: boolean;
  hideToolbox?: boolean;
  instanceRef?: (instance: EditorJsType) => void;
};

type AcceptableEditorJsProps = Omit<UsefulEditorJsProps, "instanceRef">;

type EditorProps = AcceptableEditorJsProps & {
  id: string;
  onChange?: (data?: OutputData) => void;
  setEditorRef?: (instance: EditorJsType) => void;
} & UIProps;

const Editor = ({
  id,
  onChange,
  hideToolbar,
  hideToolbox,
  placeholder,
  data,
  autofocus,
  setEditorRef,
  onEnterPressed,
  ...props
}: EditorProps) => {
  const containerRef = useRef<HTMLDivElement>();
  const [hasEnterBeenPressed, setHasEnterBeenPressed] = React.useState<boolean>(
    false
  );

  const options: UsefulEditorJsProps = {};

  if (hideToolbar) {
    options.hideToolbar = hideToolbar;
  }
  if (hideToolbox) {
    options.hideToolbox = hideToolbox;
  }
  if (autofocus) {
    options.autofocus = autofocus;
  }
  if (placeholder) {
    options.placeholder = placeholder;
  }
  if (data) {
    options.data = data;
  }
  if (setEditorRef) {
    options.instanceRef = (instance: EditorJsType) => setEditorRef(instance);
  }
  if (onEnterPressed) {
    options.onEnterPressed = () => {
      setHasEnterBeenPressed(true);
    };
  }

  useEffect(() => {
    if (hasEnterBeenPressed && onEnterPressed) {
      onEnterPressed();
      setHasEnterBeenPressed(false);
    }
  }, [hasEnterBeenPressed, onEnterPressed]);

  return (
    <EditorJs
      {...options}
      holder={id}
      onChange={(api: API, newData?: OutputData) =>
        onChange && onChange(newData)
      }
      tools={{
        inlineCode: {
          class: InlineCode,
          shortcut: "`",
        },
      }}
    >
      <Pane id={id} ref={containerRef} className="no-loader" {...props} />
    </EditorJs>
  );
};

export default Editor;
