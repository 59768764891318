import React from "react";

import { Input as AntdInput } from "antd";
import { UIProps } from "components/base/types";
import Box from "ui-box";

type AntdInputProps = React.ComponentProps<typeof AntdInput>;

export const Input = (props: AntdInputProps & UIProps) => (
  <Box is={AntdInput} {...props} />
);

type AntdPasswordInputProps = React.ComponentProps<typeof AntdInput.Password>;
Input.Password = (props: AntdPasswordInputProps & UIProps) => (
  <Box is={AntdInput.Password} {...props} />
);

type AntdSearchInputProps = React.ComponentProps<typeof AntdInput.Search>;
Input.Search = (props: AntdSearchInputProps & UIProps) => (
  <Box is={AntdInput.Search} {...props} />
);

export default Input;
