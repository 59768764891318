import React from "react";
import { Pane } from "components/base/layout";
import { useLocalStorage } from "@rehooks/local-storage";
import Editor from "components/Editor";

import {
  CLEAN_NAME_COLUMN_ID,
  UNCLEAN_NAME_COLUMN_ID,
  CLEAN_NAME_COLUMN_DESCRIPTION_BLOCKS,
  UNCLEAN_NAME_COLUMN_DESCRIPTION_BLOCKS,
} from "constants/defaults";

const ColumnDescription = ({ isOpen, column }) => {
  const [columnDescription, setColumnDescription] = useLocalStorage(
    `_mp_${column.id}-description-blocks`
  );

  if (
    !columnDescription &&
    [CLEAN_NAME_COLUMN_ID, UNCLEAN_NAME_COLUMN_ID].includes(column.id)
  ) {
    if (column.id === CLEAN_NAME_COLUMN_ID) {
      setColumnDescription(CLEAN_NAME_COLUMN_DESCRIPTION_BLOCKS);
    } else {
      setColumnDescription(UNCLEAN_NAME_COLUMN_DESCRIPTION_BLOCKS);
    }
  }

  if (!isOpen) {
    if (!columnDescription) {
      return <></>;
    }
    return (
      <Pane
        className="collapsed-rte"
        fontSize={14}
        title={columnDescription.text}
        dangerouslySetInnerHTML={{ __html: columnDescription.html }}
      />
    );
  }

  return (
    <Pane
      fontSize={14}
      cursor="auto"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Editor
        hideToolbar
        hideToolbox
        autofocus
        placeholder="Add a column description..."
        id={`column-${column.id}`}
        onChange={setColumnDescription}
        data={columnDescription}
        paddingY={6}
      />
    </Pane>
  );
};

export default ColumnDescription;
