import React, { useState } from "react";
import { Tooltip } from "antd";
import { Icon, Tag, Stat } from "components/base";
import { Code } from "components/base/type";
import { Pane, Row, Column, Span } from "components/base/layout";
import { XYChart, BarSeries, CrossHair, XAxis } from "@data-ui/xy-chart";
import styled from "styled-components";
import moment from "moment";
import ColumnDescription from "./ColumnDescription";
import CommentPopoverController from "components/CommentPopoverController";

const ColumnRow = ({ column }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledColumnPane
      key={column.id}
      className={isOpen && "isOpen"}
      borderBottom="1px solid #F2F2F2"
    >
      <Row
        width="100%"
        centerY
        minHeight={47}
        cursor="pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Code fontSize={13} fontWeight={500} width={300} flexShrink={0}>
          {column.name.toLowerCase()}
        </Code>{" "}
        <Code fontSize={13} width={100} flexShrink={0}>
          {column.data_type.toLowerCase()}
        </Code>
        <Pane width={300} flexShrink={0}>
          {column.summary &&
            column.summary.histogram &&
            column.summary.histogram.length > 0 && (
              <Pane transform="scale(0.5)" marginY={-55}>
                <XYChart
                  ariaLabel="Required label"
                  xScale={{ type: "band" }}
                  yScale={{ type: "linear" }}
                  eventTrigger="container"
                  snapTooltipToDataX
                  width={300}
                  height={150}
                  snapTooltipToDataY
                  renderTooltip={({ event, datum, data, color }) => (
                    <Pane transform="scale(2)" backgroundColor="white">
                      <Span fontWeight={500} fontFamily={`"Fira Code"`}>
                        {datum.x.toString()}
                      </Span>
                      : {datum.y.toString()}
                    </Pane>
                  )}
                >
                  <BarSeries
                    data={column.summary.histogram.map(({ COUNT, VALUE }) => ({
                      x:
                        VALUE === undefined || VALUE === null
                          ? "-"
                          : VALUE.toString(),
                      y: COUNT || 0,
                    }))}
                    fill="hsla(202, 70%, 62%, 1)"
                  />
                  <XAxis numTicks={26} tickFormat={() => {}} />
                  <CrossHair
                    stroke="#1098ad"
                    circleFill="#1098ad"
                    showVerticalLine={false}
                    fullWidth
                  />
                </XYChart>
              </Pane>
            )}
        </Pane>
        <ColumnDescription isOpen={isOpen} column={column} />
        <Row marginLeft="auto" centerY flexShrink={0}>
          <Row marginLeft={18} minWidth={70}>
            {column.pii && (
              <Tag
                color="red"
                style={{ display: "block", textAlign: "center" }}
              >
                pii
              </Tag>
            )}
            {column.joinable_columns && (
              <Tag color="orange" style={{ display: "block" }}>
                joinable
              </Tag>
            )}
          </Row>
          <Pane marginLeft={18} className={!isOpen && "onHover"}>
            {column.summary && (
              <Tooltip
                title={
                  `${column.summary.null_count} nulls (` +
                  Math.round(
                    (100 * column.summary.size) /
                      (column.summary.null_count + column.summary.size)
                  ) +
                  "% filled)"
                }
                placement="left"
              >
                <Column
                  width={4}
                  height={22}
                  border="1px solid #171E26"
                  opacity={0.6}
                >
                  <Pane
                    marginTop="auto"
                    backgroundColor="#171E26"
                    height={
                      Math.round(
                        (100 * column.summary.size) /
                          (column.summary.null_count + column.summary.size)
                      ) + "%"
                    }
                  />
                </Column>
              </Tooltip>
            )}
          </Pane>

          <Pane marginLeft={18} minWidth={54}>
            <CommentPopoverController
              hideUnlessCommentsExist
              storageKey={`_mp_${column.id}-comments`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </Pane>
          <Pane marginLeft={18}>
            <Icon
              className="onHover"
              name={isOpen ? "fa-chevron-up" : "fa-chevron-down"}
              fontSize={10}
              verticalAlign="middle"
            />
          </Pane>
        </Row>
      </Row>
      {isOpen && (
        <Row
          marginLeft={300}
          borderTop="1px solid #f5f5f5"
          paddingY={12}
          fontSize={14}
          opacity={0.8}
        >
          <Row>
            <Stat name="min">
              {column.summary &&
              column.summary.min !== undefined &&
              column.summary.min !== null
                ? column.summary.min.toString()
                : ""}
            </Stat>
            <Stat name="max">
              {column.summary &&
              column.summary.max !== undefined &&
              column.summary.max !== null
                ? column.summary.max.toString()
                : ""}
            </Stat>
            <Stat name="distinct">
              {column.summary && column.summary.distinct_count}
            </Stat>
            <Stat name="size">{column.summary && column.summary.size}</Stat>
            <Stat name="created">
              <Span title={column.created_at}>
                {moment(column.created_at).subtract("2", "months").fromNow()}
              </Span>
            </Stat>
          </Row>

          {column.joinable_columns && (
            <Pane marginLeft={60}>
              <Stat name="joinable columns">
                <Column alignItems="start">
                  {column.joinable_columns.map((joinableColumn) => (
                    <Code
                      fontSize={12}
                      paddingY={2}
                      paddingX={8}
                      backgroundColor="#eee"
                      borderRadius={2}
                      marginY={4}
                    >
                      {joinableColumn.db_name}.{joinableColumn.schema_name}.
                      {joinableColumn.table_name}.{joinableColumn.column}
                    </Code>
                  ))}
                </Column>
              </Stat>
            </Pane>
          )}
        </Row>
      )}
    </StyledColumnPane>
  );
};

const StyledColumnPane = styled(Pane)`
  input.ant-input.unstyled {
    background-color: transparent;
    padding-left: 0;
    border-color: transparent;

    &::placeholder {
      text-align: left;
    }

    &:hover {
      border-color: transparent;
    }
  }
  &.isOpen .onHover {
    opacity: 1;
  }

  .onHover {
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:hover,
  &:focus-within {
    /*    i {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
*/
    i.fa-chevron-down,
    i.fa-chevron-up,
    .onHover {
      opacity: 1;
    }
  }
`;

export default ColumnRow;
