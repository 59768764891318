import React from "react";
import { useQuery, gql } from "@apollo/client";
import { MetaTitle, Stat, PersonIcon } from "components/base";
import { H2, Code } from "components/base/type";
import { Pane, Row, Span } from "components/base/layout";
import moment from "moment";
import ColumnRow from "./ColumnRow";
import TableDescription from "./TableDescription";

const TablePage = ({ table }) => {
  const { loading, data } = useQuery(getColumnForTableQuery(table.id));

  if (loading) {
    return <></>;
  }

  const sortedColumns = [...data.columns].sort(sortColumns);

  const title = `${table.schema.toLowerCase()}.${table.name.toLowerCase()}`;

  return (
    <>
      <MetaTitle>{title}</MetaTitle>
      <Row marginTop={-10}>
        <Row minWidth={650}>
          <Pane>
            <Pane
              fontSize={12}
              textTransform="uppercase"
              opacity={0.5}
              marginBottom={2}
            >
              <Code>{table.db}</Code>
            </Pane>

            <H2 marginBottom={21}>{title}</H2>
          </Pane>
          <Pane marginLeft="auto" paddingLeft={20}>
            <Row>
              <Stat name="Cols">
                <Span opacity={0.9} fontSize={14}>
                  {table.col_count}
                </Span>
              </Stat>
              <Stat name="Rows">
                <Span opacity={0.9} fontSize={14}>
                  {table.row_count}
                </Span>
              </Stat>
              <Stat name="Altered" paddingRight={0}>
                <Span opacity={0.9} fontSize={14}>
                  {moment(table.last_altered).fromNow()}
                </Span>
              </Stat>
            </Row>
          </Pane>
        </Row>
        <PersonIcon.Group marginLeft="auto">
          <PersonIcon name="Peter Casinelli" email="peter@metaplane.dev" />
          <PersonIcon name="Laura Powell" email="laura@metaplane.dev" />
          <PersonIcon name="Kevin Hu" email="kevin@metaplane.dev" />
        </PersonIcon.Group>
      </Row>

      <TableDescription tableId={table.id} />

      <Pane marginTop={30}>
        {sortedColumns.map((column) => (
          <ColumnRow column={column} key={column.id} />
        ))}
      </Pane>
    </>
  );
};

const getColumnForTableQuery = (tableId) => gql`
  query GetColumnForTable {
    columns(where: {table: {_eq: "${tableId}"}}) {
    updated_at
    table
    summary
    schema
    position
    pii
    name
    last_altered
    joinable_columns
    is_nullable
    id
    db
    data_type
    created_at
    created
  }
}`;

const sortColumns = (columnA, columnB) => {
  if (!columnA.summary && columnB.summary) {
    return 1;
  }
  if (!columnB.summary && columnA.summary) {
    return -1;
  }
  if (!!columnB.summary && !!columnA.summary) {
    if (columnA.summary.size < columnB.summary.size) {
      return 1;
    }
    if (columnB.summary.size < columnA.summary.size) {
      return -1;
    }
  }

  if (columnB.position < columnA.position) {
    return 1;
  }
  if (columnB.position > columnA.position) {
    return -1;
  }
  return 0;
};
export default TablePage;
