import React from "react";
import { Pane } from "components/base/layout";
import { UIProps } from "components/base/types";

export const H2 = (props: UIProps) => (
  <Pane
    is="h2"
    margin={0}
    fontSize={18}
    fontWeight={600}
    color="#171E26"
    {...props}
  />
);
