import React from "react";
import { useRouteMatch } from "react-router-dom";
import SearchBar from "./SearchBar";
import { Dropdown, Menu } from "antd";
import { Row, Pane, Span } from "components/base/layout";
import { Icon, Link, NavLink } from "components/base";

const LogoMark = () => (
  <Pane marginTop={5.5} marginLeft={5.5}>
    <Pane
      borderTop="2.5px solid #171E26"
      borderLeft="2.5px solid #171E26"
      width={20}
      height={20}
      position="absolute"
      marginLeft={-5.5}
      marginTop={-5.5}
    ></Pane>
    <Pane border="2.5px solid #171E26" width={20} height={20}></Pane>
  </Pane>
);

const Logo = () => (
  <Row centerY flexGrow={1} flexBasis={0}>
    <LogoMark />
    <Span fontWeight={600} fontSize={18} marginLeft={11.5}>
      demo
    </Span>
  </Row>
);

const menu = (
  <Menu>
    <Menu.Item>
      <NavLink to="/settings/connections">Connections</NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink to="/settings/team">Team</NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink to="/settings/profile">Profile</NavLink>
    </Menu.Item>
  </Menu>
);

const HeaderActions = () => {
  const match = useRouteMatch({
    path: "/settings",
    sensitive: true,
  });

  return (
    <Row flexGrow={1} flexBasis={0}>
      <Link active={!!match} marginLeft="auto">
        <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
          <Pane>
            <Icon name="fa-cog" fontSize={22} />
          </Pane>
        </Dropdown>
      </Link>
    </Row>
  );
};

const Header = () => (
  <Row centerY paddingTop={35} paddingX={72}>
    <Logo />
    <SearchBar />
    <HeaderActions />
  </Row>
);

export default Header;
