import React, { useState, useContext } from "react";
import { AutoComplete, Input } from "antd";
import { Link } from "components/base";
import { Span, Row } from "components/base/layout";
import AppContext from "AppContext";
import Fuse from "fuse.js";
import moment from "moment";
import { getFormattedNumber, getTableSlug } from "utils";

const SearchBar = () => {
  const { tables } = useContext(AppContext);
  const [options, setOptions] = useState([]);

  const handleSearch = (value) => {
    setOptions(value ? searchResult(value) : []);
  };

  const searchResult = (query) => {
    let keys = ["name", "db", "schema"];
    let subquery = query;
    if (query.indexOf("db:") === 0) {
      keys = ["db"];
      subquery = query.slice(3);
    }
    if (query.indexOf("table:") === 0) {
      keys = ["name"];
      subquery = query.slice(6);
    }
    if (query.indexOf("schema:") === 0) {
      keys = ["schema"];
      subquery = query.slice(7);
    }

    const config = {
      keys,
    };

    const fuse = new Fuse(tables, config);

    const results = fuse.search(subquery);

    return results.map(({ item }) => ({
      value: getTableSlug(item),
      label: (
        <Link unstyled to={`/d/t/${getTableSlug(item)}`}>
          <Row color="#171E26">
            <Span
              fontSize={12}
              fontFamily="Fira Code"
              textTransform="uppercase"
              opacity={0.65}
            >
              {item.db}.{item.schema}
            </Span>
            <Span marginLeft="auto" opacity={0.9} fontSize={12}>
              {item.last_altered && moment(item.last_altered).fromNow()}
            </Span>
          </Row>
          <Row color="#171E26">
            <Span
              fontWeight={500}
              flexShrink={1}
              textOverflow="ellipsis"
              overflow="hidden"
              fontFamily="Fira Code"
              textTransform="lowercase"
            >
              {item.name}
            </Span>
            <Span
              opacity={0.65}
              marginLeft="auto"
              paddingLeft={20}
              fontSize={12}
            >
              {!!item.col_count && `${getFormattedNumber(item.col_count)} cols`}
              {!!item.col_count && !!item.row_count && ", "}
              {!!item.row_count && `${getFormattedNumber(item.row_count)} rows`}
            </Span>
          </Row>
        </Link>
      ),
    }));
  };

  return (
    <AutoComplete
      dropdownClassName="certain-category-search-dropdown"
      dropdownMatchSelectWidth={500}
      style={{
        width: 600,
      }}
      onSearch={handleSearch}
      options={options}
    >
      <Input.Search
        size="large"
        placeholder="Search databases, tables, columns, schemas, and services..."
        style={{
          fontFamily: `"Fira Code", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace`,
        }}
      />
    </AutoComplete>
  );
};

export default SearchBar;
