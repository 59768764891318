import React from "react";
import { Icon } from "components/base";
import { Pane, Row, Span } from "components/base/layout";
import styled from "styled-components";
import classNames from "classnames";

const StyledCommentPopoverButton = styled(Row)`
  &:hover {
    background: hsla(212, 25%, 12%, 0.08);
  }
`;

const CommentPopoverButton = ({
  comments,
  hideUnlessCommentsExist,
  ...props
}) => {
  const hasComments = comments && !!comments.length;
  return (
    <StyledCommentPopoverButton
      role="button"
      outline="none"
      tabIndex="0"
      userSelect="none"
      transition="background 20ms ease-in 0s"
      cursor="pointer"
      display="inline-flex"
      center
      borderRadius={3}
      height={24}
      paddingX={6}
      alignSelf="flex-start"
      marginLeft={4}
      marginTop={3}
      className={classNames(
        hideUnlessCommentsExist && !hasComments && "onHover"
      )}
      {...props}
    >
      <Icon name="fa-comment" fontSize={14} opacity={0.4} flexShrink={0} />
      {comments && !!comments.length && (
        <Pane
          fontSize={12}
          fontWeight={500}
          textTransform="uppercase"
          letterSpacing={0.5}
          color="rgba(55, 53, 47, 0.6)"
          marginLeft={4}
        >
          <Span
            marginLeft={2}
            color="hsla(212, 25%, 12%, 0.6)"
            fontWeight={600}
          >
            {comments.length}
          </Span>
        </Pane>
      )}
    </StyledCommentPopoverButton>
  );
};

export default CommentPopoverButton;
